// Pulled from:
// https://joshwcomeau.com/snippets/react-hooks/use-prefers-reduced-motion

import { useEffect, useState } from 'react';

const QUERY = '(prefers-color-scheme: dark)';

const isRenderingOnServer = typeof window === 'undefined';

const getInitialState = () => {
  // For our initial server render, we won't know if the user
  // prefers reduced motion, but it doesn't matter. This value
  // will be overwritten on the client, before any animations
  // occur.
  return isRenderingOnServer ? true : window.matchMedia(QUERY).matches;
};

function useDarkMode() {
  const [prefersDarkMode, setPrefersDarkMode] = useState(
    getInitialState
  );

  useEffect(() => {
    const mediaQueryList = window.matchMedia(QUERY);
    const listener = (event) => {
      setPrefersDarkMode(event.matches);
    };
    mediaQueryList.addListener(listener);
    return () => {
      mediaQueryList.removeListener(listener);
    };
  }, []);
  return prefersDarkMode;
}

export default useDarkMode;
